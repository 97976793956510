import React, { Component } from 'react';

import {
    InfoGraphicText,
    Paragraph,
    SmallHeadline,
    Wrapper
} from './style';

export default class Facts extends Component {
    /*
    public componentDidMount() {
        this.props.didMount('Facts');
    }
    */
    render() {
        return (
            <Wrapper>
                <ul>
                    <li>
                        <InfoGraphicText>25</InfoGraphicText>
                        <SmallHeadline>Jahre</SmallHeadline>
                        <Paragraph>
                            Familienunternehmen in zweiter Generation.
                        </Paragraph>
                    </li>
                    <li>
                        <InfoGraphicText>2300</InfoGraphicText>
                        <SmallHeadline>Balkonanlagen</SmallHeadline>
                        <Paragraph>
                            Langjährige Erfahrung durch zahlreiche Projekte.
                        </Paragraph>
                    </li>
                    <li>
                        <InfoGraphicText>40.000</InfoGraphicText>
                        <SmallHeadline>Quadratmeter</SmallHeadline>
                        <Paragraph>
                            Mehrwert durch neu geschaffene Wohnfläche.
                        </Paragraph>
                    </li>
                </ul>
            </Wrapper>
        );
    }
}
