import React from 'react'

import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text, Link } from '@rebass/emotion'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const breakpoints = ['32em', '48em', '']
const mq = breakpoints.map(
    bp => `@media (min-width: ${bp}em)`
)

const content = {
    headline: 'Anbaubalkone als Komplettleistung aus einer Hand.',
    services: [
        {
            description: 'Wir planen und entwickeln Ihr Balkonanlage individuell für Ihr Objekt.',
            headline: 'Planung',
        },
        {
            description: 'In unserer werkseigenen Produktion werden sämtliche für Ihre Balkonanlage notwendigen Bestandteile hergestellt.',
            headline: 'Produktion',
        },
        {
            description: 'Die bundesweite Montage unserer Balkonanlagen erfolgt durch hochqualifizierten Monteure mit langjähriger Erfahrung im Balkonbau.',
            headline: 'Montage',
        },
    ],
}


export default () =>
    <StaticQuery
        query={query}
        render={(data) => (
            <div css={{ position: 'relative' }}>
                <Box mx='auto' css={{
                    maxWidth: '1450px',
                    zIndex: 1,
                    position: 'relative'
                }}>
                    <GatsbyImage image={getImage(data.fileName)} alt="Balkonsysteme" />
                </Box>
                <Box zIndex={2} mx='auto' mt={[0, -70]} css={{
                    maxWidth: '1250px',
                    zIndex: 2,
                    position: 'relative'
                }}>
                    <Flex flexDirection={["column", "row"]} p={[25, 30, 30, 40]} css={{ backgroundColor: '#4975ba' }}>
                        <Box width={[1, 0.5]} p={[0, 10, 10, 20]} mb={[15, 0]} >
                            <Heading lineHeight={1.4} fontWeight={500} fontSize={[18, 24, 24, 36]} color="#fff">{content.headline}</Heading>
                        </Box>
                        <Box width={[1, 0.5]} p={[0, 20]}>
                            <Text fontSize={16} fontWeight={300} lineHeight={1.6} color='#fff'>
                                Balkonsysteme Taucha ist ihr Partner für Planung, Produktion und Montage von Balkonanlagen in ganz Deutschland.
                            </Text>
                        </Box>
                    </Flex>
                </Box>
                <Box zIndex={2} mx='auto' my={[0, 65]} css={{
                    maxWidth: '1150px'
                }}>
                    <Box px={[30, 30, 30, 40]} mt={[30, 40]} mb={[0, 40]}>
                        <Heading mb={[0, 20]} fontSize={[24, 36]} fontWeight={400} color="rgb(18, 50, 80)" textAlign={["left"]}>Höchste Flexibilität durch langjährige Erfahrung</Heading>
                        <Text textAlign="left" lineHeight={2} fontSize={[10, 16]} color="rgb(18, 50, 80)"
                            css={{
                                maxWidth: '72%',
                                display: 'none',
                                [mq[0]]: {
                                    display: 'block'
                                },
                            }}>
                            Ob größere Wohnanlage oder ein zentral gelegenes Mehrfamilienhaus. Durch
                            unsere Lösungen garantieren wir höchste Flexibilität mit Balkonen speziell für Ihr Objekt.
                            Dabei garantieren wir eine Montage ohne Kran und ohne Gerüst als wirtschaftliche Alternative.
                        </Text>
                        <Link my={20} href="/leistungen" css={{ textDecoration: "none", display: 'inline-block' }}>
                            <Text textAlign="left" lineHeight={2} fontWeight={600} fontSize={[10, 16]} color="rgb(18, 50, 80)">Unsere Leistungen <span />&#8594;</Text>
                        </Link>
                    </Box>
                    <Flex flexDirection={['column', 'row']} mb={[20, 40]} mt={[0, 40]}>
                        {content.services.map(({ headline, description }, idx) => (
                            <Box key={idx} py={[20, 30, 30, 40]} px={[30, 30, 30, 40]} width={[1, 0.333]}>
                                <Heading mb={20} fontSize={22} fontWeight={500} color="rgb(18, 50, 80)" textAlign={["left"]}>{headline}</Heading>
                                <Text textAlign="left" lineHeight={2} fontSize={[16, 16]} color="rgb(18, 50, 80)">{description}</Text>
                            </Box>
                        ))}
                    </Flex>
                </Box>
            </div>
        )}
    />


const query = graphql`
    query {
        fileName:   file(name: {eq: "hero"}) {
    name 
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            } 
        }
    }
`
