import styled from '@emotion/styled';

import {
    InfoGraphicFont,
    InfoGraphicSmallHeadline,
    MyHeadline12,
    ParagraphType
} from '../../../style/fonts';

export const Headline = styled('h2')`
    ${MyHeadline12};
    text-align: center;
    color: $blue2;
    margin: 0;
`;

export const Paragraph = styled('p')`
    ${ParagraphType};
    text-align: center;
    max-width: 760px;
    margin: 0 auto;

    margin-top: 0.75em;
    max-width: 80%;
`;

export const SmallHeadline = styled('div')`
    ${InfoGraphicSmallHeadline};
    margin-top: 0.4em;
    display: inline-block;

    @media screen and (min-width: 50em) {
        margin-top: 1.4rem;
    }

    color: $blue2;
`;

export const InfoGraphicText = styled('div')`
    ${InfoGraphicFont};

    @media (min-width: 50em) {
        font-size: 6rem;
    }
`;

export const Wrapper = styled('section')`
    padding: 6rem 0;

    @media screen and (min-width: 50em) {
        padding: 12rem 0;
    }

    width: 90%;
    margin: 0 auto;

    > ul {
        display: flex;

        @media not screen and (min-width: 50em) {
            flex-direction: column;
        }

        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 2rem 0 0 0;

        @media screen and (min-width: 50em) {
            margin: 5rem 0 0 0;
        }

        > li {
            text-align: center;

            @media not screen and (min-width: 50em) {
                width: 85%;
                margin: 2rem auto;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @media screen and (min-width: 50em) {
                width: 25rem;
            }

            > em,
            > div,
            > p {
                display: block;
            }

            > div {
            }

            > em {
                @include InfoGraphicText;
                color: $blue2;

                > span {
                    padding-left: 0.5rem;
                    font-size: 2.5rem;
                }
            }

            > p {
                @include ParagraphSmaller;
            }
        }
    }
`;
