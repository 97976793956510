import React from 'react';

// import { Link } from 'gatsby'

import Layout from '../components/layout'
// import Image from '../components/image'
import AboveFold from '../components/home/AboveFold'
import Facts from '../components/home/Facts'
import Gallery from '../components/home/Gallery'
import SecondFold from '../components/home/SecondFold'


const IndexPage = () => {
    return (
        <Layout>
            <AboveFold />
            <SecondFold />
            <Facts />
            <Gallery />
        </Layout>
    )
}

export default IndexPage
